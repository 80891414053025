import { defineStore } from "pinia";
import { useNotifications } from "~/stores/notifications.js";
import { useAuth } from "~/stores/auth.js";
import { useDateTime } from "~/composables/useDateTime.js";
import { useOrderLine } from "~/composables/useOrderLine.js";

export const useOrderStore = defineStore("orderStore", {
  state: () => ({
    // The current state of the order on the server.
    order: null,

    // Order attributes that can be sent when order is stored.
    comment: null,
    isTakeaway: null,
    accountId: null,
    accountConfigurationId: null,
    tipAmount: 0,
    adultCount: 0,
    childCount: 0,
    orderProviderId: null,
    deliveryId: null,
    orderProviderOrderNumber: null,
    customerName: null,
    customerPhone: null,
    pickupExpectedAt: null,
    draftOrderLines: [],
    tables: [],

    delivery: null,
    accountAttributes: null,

    // Screen state
    modal: null,
    selected: null,
  }),

  actions: {
    load(order = null) {
      this.selected = null;

      this.order = order;

      this.comment = order?.attributes.comment || null;
      this.isTakeaway = order?.attributes.is_takeaway ?? null;
      this.accountId = order?.attributes.account_id || null;
      this.accountConfigurationId =
        order?.attributes.account_configuration_id || null;
      this.tipAmount = order?.attributes.tip_amount ?? 0;
      this.adultCount = order?.attributes.adult_count ?? 0;
      this.childCount = order?.attributes.child_count ?? 0;
      this.orderProviderId = order?.attributes.order_provider_id || null;
      this.deliveryId = order?.attributes.delivery_id || null;
      this.orderProviderOrderNumber =
        order?.attributes.order_provider_order_number || null;
      this.customerName = order?.attributes.customer_name || null;
      this.customerPhone = order?.attributes.customer_phone || null;
      this.pickupExpectedAt = useDateTime(order?.attributes.pickup_expected_at);
      this.tables = order?.attributes.tables ?? [];

      this.delivery = order?.attributes.delivery ?? null;
      this.accountAttributes = order?.attributes.account_attributes ?? null;

      this.draftOrderLines = [];
    },

    async setDetails(data, successMessage) {
      if (this.exists) {
        return await this.submit({
          url: `/orders/${this.id}`,
          method: "PUT",
          body: {
            pickup_expected_at: data.pickupExpectedAt,
            order_provider_id: data.orderProviderId,
            order_provider_order_number: data.orderProviderOrderNumber,
            customer_name: data.customerName,
            customer_phone: data.customerPhone,
          },
          successMessage,
        });
      }

      // Fallback in case the order doesn't exist
      this.pickupExpectedAt = data.pickupExpectedAt;
      this.orderProviderId = data.orderProviderId;
      this.orderProviderOrderNumber = data.orderProviderOrderNumber;
      this.customerName = data.customerName;
      this.customerPhone = data.customerPhone;

      this.notifySuccess(successMessage);
      return true;
    },

    async setTip(value, successMessage) {
      if (this.exists) {
        return await this.submit({
          url: `/orders/${this.id}/tip`,
          method: "PUT",
          body: { tip: value },
          successMessage,
        });
      }

      // Fallback in case the order doesn't exist
      this.tipAmount = value;

      this.notifySuccess(successMessage);

      return true;
    },

    async setGuestCount(adultCount, childCount, successMessage) {
      if (this.exists) {
        return await this.submit({
          url: `/orders/${this.id}/guest-count`,
          method: "PUT",
          body: { adult_count: adultCount, child_count: childCount },
          successMessage,
        });
      }

      // Fallback in case the order doesn't exist
      this.adultCount = adultCount;
      this.childCount = childCount;

      this.notifySuccess(successMessage);
      return true;
    },

    async setTables(tables, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/tables`,
        method: "PUT",
        body: { tables: tables.map((id) => ({ id })) },
        successMessage,
      });
    },

    async setAccount(account, accountConfiguration, successMessage) {
      if (this.exists) {
        return await this.submit({
          url: `/orders/${this.id}/account`,
          method: "PUT",
          body: {
            account_id: account.id,
            account_configuration_id: accountConfiguration.id,
          },
          successMessage,
        });
      }

      // Fallback in case the order doesn't exist
      this.accountAttributes = {
        name: account.attributes.name,
        number: account.attributes.number,
        active_from: account.attributes.active_from,
        active_to: account.attributes.active_to,
      };
      this.accountId = account.id;
      this.accountConfigurationId = accountConfiguration.id;

      this.notifySuccess(successMessage);
      return true;
    },

    async removeAccount(successMessage) {
      if (this.exists) {
        return await this.submit({
          url: `/orders/${this.id}/account`,
          method: "DELETE",
          successMessage,
        });
      }

      this.accountAttributes = null;
      this.accountId = null;
      this.accountConfigurationId = null;

      this.notifySuccess(successMessage);
      return true;
    },

    async setDelivery(delivery, successMessage) {
      if (this.exists) {
        return await this.submit({
          url: `/orders/${this.id}/delivery`,
          method: "PUT",
          body: { delivery_id: delivery.id },
          successMessage,
        });
      }

      this.delivery = {
        ...delivery.attributes,
      };

      this.deliveryId = delivery.id;

      this.notifySuccess(successMessage);

      return true;
    },

    async setComment(comment, successMessage) {
      this.comment = comment;

      this.notifySuccess(successMessage);
      return true;
    },

    async storeOrderDiscountLine(data, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/discount-lines`,
        method: "POST",
        body: data,
        successMessage,
      });
    },

    async cancelOrderDiscountLine(id, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/discount-lines/${id}/cancel`,
        method: "POST",
        successMessage,
      });
    },

    async setOrderLineComment(id, comment) {
      this.draftOrderLines.find(
        (draftOrderLine) => draftOrderLine.id === id,
      ).comment = comment;
    },

    async cancelOrderLine(id, cancellationReason, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/order-lines/cancel`,
        method: "POST",
        body: {
          cancellation_reason_id: cancellationReason.id,
          order_lines: [{ id }],
        },
        successMessage,
      });
    },

    async storeOrderLineDiscountLine(id, data, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/order-lines/${id}/discount-lines`,
        method: "POST",
        body: data,
        successMessage,
      });
    },

    async pay(paymentMethod, amount, transactionReference, successMessage) {
      if (!(await this.save())) {
        return false;
      }

      return await this.storePayment(
        paymentMethod,
        amount,
        transactionReference,
        successMessage,
      );
    },

    async storePayment(
      paymentMethod,
      amount,
      transactionReference,
      successMessage,
    ) {
      return await this.submit({
        url: `/orders/${this.id}/payments`,
        method: "POST",
        body: {
          terminal_payment_method_id: paymentMethod.id,
          transaction_reference: transactionReference,
          amount: amount,
        },
        successMessage,
      });
    },

    async cancelPayment(id, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/payments/${id}/cancel`,
        method: "POST",
        successMessage,
      });
    },

    async storeReceipt(category, successMessage) {
      if (!(await this.save())) {
        return false;
      }

      return await this.submit({
        url: `/orders/${this.id}/receipts`,
        method: "POST",
        body: {
          category: category,
          printer_id: useAuth().printerId,
          receipt_transport_id: "printer",
        },
        successMessage,
      });
    },

    async cancel(cancellationReason, successMessage) {
      return await this.submit({
        url: `/orders/${this.id}/cancel`,
        method: "POST",
        body: {
          cancellation_reason_id: cancellationReason.id,
        },
        successMessage,
      });
    },

    async finish(successMessage) {
      return (
        (await this.save()) &&
        (await this.submit({
          url: `/orders/${this.id}/finish`,
          method: "POST",
          successMessage,
        }))
      );
    },

    async save() {
      if (this.exists) {
        return this.storeOrderLines();
      }

      return this.store();
    },

    async storeOrderLines() {
      if (!this.draftOrderLines.length) {
        return true;
      }

      return await this.submit({
        url: `/orders/${this.id}/order-lines`,
        method: "POST",
        body: { order_lines: this.draftOrderLines },
      });
    },

    async store(data = {}) {
      return await this.submit({
        url: `/orders`,
        method: "POST",
        body: {
          order_lines: this.draftOrderLines,
          tables: this.tables,
          comment: this.comment,
          is_takeaway: this.isTakeaway,
          tip: this.tipAmount,
          adult_count: this.adultCount,
          child_count: this.childCount,
          pickup_expected_at: this.pickupExpectedAt,
          order_provider_id: this.orderProviderId,
          delivery_id: this.deliveryId,
          order_provider_order_number: this.orderProviderOrderNumber,
          customer_name: this.customerName,
          customer_phone: this.customerPhone,
          account_id: this.accountId,
          account_configuration_id: this.accountConfigurationId,
          ...data,
        },
      });
    },

    addDraftOrderLine(draftOrderLine) {
      this.draftOrderLines.push(draftOrderLine);
    },

    getProductCount(productId) {
      return this.orderLines
        .concat(this.draftOrderLines)
        .filter(
          (orderLine) =>
            orderLine.content_type === "products" &&
            orderLine.content_id === productId,
        )
        .reduce((sum, orderLine) => sum + orderLine.quantity, 0);
    },

    getBundleCount(bundleId) {
      return this.orderLines
        .concat(this.draftOrderLines)
        .filter(
          (orderLine) =>
            orderLine.content_type === "bundles" &&
            orderLine.content_id === bundleId,
        )
        .reduce((sum, orderLine) => sum + orderLine.quantity, 0);
    },

    // Helper method for API calls
    async submit({ url, method, body, successMessage }) {
      try {
        const response = await useApi(url, {
          method,
          body,
        });

        this.load(response.data);

        if (successMessage) {
          this.notifySuccess(successMessage);
        }

        return true;
      } catch (error) {
        if (error.statusCode === 400) {
          this.notifyError(error.data.message);
          return false;
        }

        this.notifyError("An unexpected error occurred");

        throw error;
      }
    },

    // Helper methods for notifications and translations
    notifySuccess(message) {
      useNotifications().success(message);
    },

    notifyError(message) {
      useNotifications().error(message);
    },

    openModal(name) {
      this.modal = name;
    },

    closeModal() {
      this.modal = null;
    },

    toggleSelected(type, id) {
      if (this.isSelected(type, id)) {
        return this.unselect();
      }

      this.select(type, id);
    },

    select(type, id) {
      this.selected = { type, id };
    },

    unselect() {
      this.selected = null;
    },

    isSelected(type, id) {
      if (this.selected === null) {
        return false;
      }

      return this.selected.type === type && this.selected.id === id;
    },
  },

  getters: {
    id: (state) => state.order?.id,
    merchantNumber: (state) => state.order?.attributes.merchant_number,
    finishedAt: (state) => useDateTime(state.order?.attributes.finished_at),
    cancelledAt: (state) => useDateTime(state.order?.attributes.cancelled_at),
    payments: (state) => state.order?.attributes.payments || [],
    discountLines: (state) => state.order?.attributes.discount_lines || [],
    orderLines: (state) => state.order?.attributes.order_lines || [],

    exists() {
      return !!this.id;
    },

    isComplete: (state) =>
      state.finishedAt !== null || state.cancelledAt !== null,
    hasAccount: (state) => state.accountId !== null,
    orderLineDiscountLines() {
      return this.orderLines.reduce(
        (discountLines, orderLine) => [
          ...discountLines,
          ...orderLine.discount_lines,
        ],
        [],
      );
    },
    allDiscountLines() {
      return [...this.discountLines, ...this.orderLineDiscountLines];
    },
    discountPrice() {
      return this.allDiscountLines
        .filter((discountLine) => !discountLine.cancelled_at)
        .reduce((total, discountLine) => {
          return total + discountLine.price;
        }, 0);
    },
    deliveryPrice() {
      return this.delivery?.price || 0;
    },
    grossPrice() {
      return (
        this.tipAmount +
        this.deliveryPrice +
        this.orderLines
          .concat(this.draftOrderLines)
          .filter((orderLine) => !orderLine.cancelled_at)
          .reduce(
            (result, orderLine) => result + useOrderLine(orderLine).grossPrice,
            0,
          )
      );
    },
    netPrice() {
      return this.grossPrice - this.discountPrice;
    },
    amountPaid() {
      return this.payments
        .filter((payment) => !payment.cancelled_at)
        .reduce((total, payment) => total + payment.amount, 0);
    },
    amountPaidWithoutChange() {
      return this.payments
        .filter((payment) => !payment.cancelled_at)
        .filter((payment) => !payment.is_change)
        .reduce((total, payment) => total + payment.amount, 0);
    },
    changeAmount() {
      return this.payments
        .filter((payment) => !payment.cancelled_at)
        .filter((payment) => payment.is_change)
        .reduce((total, payment) => total + payment.amount, 0);
    },
    amountToPay() {
      return this.netPrice - this.amountPaid;
    },

    hasValidDraftOrderLines: (state) =>
      state.draftOrderLines.every(
        (draftOrderLine) => useOrderLine(draftOrderLine).isValid,
      ),
    hasActiveDiscountLines() {
      return (
        this.allDiscountLines.filter(
          (discountLine) => !discountLine.cancelled_at,
        ).length > 0
      );
    },

    isCancellable() {
      return this.payments.every((payment) => !payment.captured_at);
    },
  },
});
